body {
  background-color: #333;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

textarea:hover {
  cursor: pointer;
}
